<template>
  <div>
    <div class="title">
      <div class="container box-series" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <img src="@/assets/icons/back.svg" v-if="localDisplayLang === 'eng'" alt @click="initialRoute" />
        <img src="@/assets/icons/back.svg" class="rotate-img" v-else @click="initialRoute" alt />
        &nbsp;&nbsp;&nbsp;
        <p>{{ localDisplayLang === "ara" ? screen.title.ar : screen.title.en }}</p>
      </div>
    </div>
    <div v-if="loader">
      <div v-for="(item, i) in 4" :key="i">
        <categoriesPlaceholder></categoriesPlaceholder>
      </div>
    </div>
    <div class="grid-view" v-else>
      <noorPlayGrid
        :contents="freemiumContent"
        :section="screen"
        :message="errorMessage"
        :isScrollable="isScrollable"
        @loadMore="loadMoreContents()"
      ></noorPlayGrid>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  data() {
    return {
      loader: true,
      freemiumContent: [],
      errorMessage: null,
      localDisplayLang: null,
      page: 1,
      isScrollable: false,
      screen: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.getContentScreen();
  },
  mounted() {
    eventBus.$on("get-seeAll-data", (response) => {
      console.log("ramadan contents", response);

      if (!response.reason) {
        if (this.freemiumContent && this.freemiumContent.length > 0) {
          this.freemiumContent = [...this.freemiumContent, ...response.data];
        } else {
          this.freemiumContent = [...response.data];
        }
        //Set isScrollable flag to true.
        this.isScrollable = response.totalcount === this.freemiumContent.length ? false : true;
        this.loader = false;
      } else {
        this.loader = false;
        this.errorMessage = response.reason;
      }
    });

    eventBus.$on("check-local-storage", (response) => {
      this.localDisplayLang = response;
    });
  },
  methods: {
    //to get the screens from appconfig object.
    getContentScreen() {
      let menuId = this.$router.currentRoute.name;

      let index = this.appConfig.screens.findIndex((element) => {
        return element.id === menuId;
      });

      if (index > -1) {
        this.screen = this.appConfig.screens[index].sections[0];
        //get all detail component contents.
        this.getAllContents();
      }
    },
    getAllContents() {
      let payload = {
        endpoint: this.screen.endpoint,
        params: this.screen.parameters,
      };
      payload.params.displaylanguage = this.localDisplayLang;
      payload.params.page = this.page;

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        payload.params.genre = "Kids";
      }

      eventBus.$emit("grid-see-all-data", payload);
    },
    initialRoute() {
      this.$router.push({ name: "Home" });
    },
    loadMoreContents() {
      this.page = this.page + 1;
      this.getAllContents();
    },
  },
  components: {
    categoriesPlaceholder: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
    noorPlayGrid: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.title {
  background: linear-gradient(90deg, #1d1d1d 0%, #1a1a1a 100%);
  .box-series {
    display: flex;
    img {
      cursor: pointer;
      width: 1rem;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    .rotate-img {
      transform: rotate(180deg);
    }
    p {
      opacity: 1;
      color: $clr-light-gd3;
      font-family: $font-regular;
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.5rem;
    }
  }
}
.grid-view {
  height: 100%;
}
</style>
